div.or-line { width:100%; text-align:center; border-bottom: 1px solid #000; line-height:0.1em; margin:10px 0 20px; }
div.or-line span { background:#fff; padding:0 10px; }

.pace .pace-progress {
  background: $primary;
}

.list-info .info .light-title {
  color: #b4b7c8;
}

.mw-20 {
  max-width: 20px;
}

.table-end-left-padding {
  padding: 15px 0px 15px 5px !important;
}

.table-end-right-padding {
  padding: 15px 0px 15px 15px !important;
}

.default-dark {
  color: $default-dark;
}

.receipt-icon {
  width: 40px;
}

.list-info {
  .receipt-icon {
    line-height: 40px;
    width: 40px;
    text-align: center;
    font-size: 17px;
    float: left;
  }
}

.receipt-amount-symbol {
  .sale {
    color: $default-success;
  }

  .purchase {
    color: $default-warning;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.booking-status-information {
  border-bottom: 1px dotted;
}

th .checkbox {
  padding: 0px;
  label {
    margin-bottom: 0px;
  }
}

chart {
  display: block;
}

.scroll-wrapper {
  height: calc(100vh - #{6 * $spacer});
  overflow: auto;
}

.fs-scroll-wrapper {
  height: calc(100vh - #{12 * $spacer});
  overflow: auto;
}

.table-header-button {
  margin-bottom: 0px;
  padding: 5px;
  height: 30px;
}

.table-header-action-row {
  line-height: 3em;
}

.export-column-list .list-group-item {
  padding-top: 0px;
  padding-bottom: 0px;
}

.input-group-addon-currency {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 9px;
  color: $dark;
}

.input-group-addon-refresh {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 12px;
  color: #888da8;
}

.file-manager-buttons {
  margin-bottom: $spacer;
}

.google-map-wrapper {
  height: 100% !important;
  width: 100% !important;
  padding-bottom: 20px;
  display: block;
}

.google-map {
  height: 100% !important;
  width: 100% !important;
}

.document-split-input {
  height: 42px;
  line-height: 42px;

  span, input, select {
    margin-right: 8px;
    width: 60px;
    display: inline;
  }
}

.document-thumbnails {
  max-height: 500px;
  overflow-y: auto;
}

.document-thumbnail p {
  padding: 10px 0;
}

.document-thumbnail img {
  border-right: 3px dotted #ddd;
}

.document-thumbnail:hover img {
  cursor: pointer;
}

.document-thumbnail img.split {
  border-right: 3px dotted $primary;
}

.document-thumbnail:last-child img {
  cursor: default;
  border-right-color: transparent;
}

table.align-middle {
  tr, td, th {
    vertical-align: middle;
  }
}

.card-settings-toggles > .card-block > div:last-child hr {
  display: none;
}

ng-select.is-invalid .ng-select-container {
  border-color: $red;
}

ng-select.is-invalid ~ .invalid-feedback {
  display: block;
}

.h-scroll {
  height: 90vh; /* %-height of the viewport */
  overflow-y: scroll;
}

.side-nav .side-nav-inner .side-nav-menu li.dropdown.active > a .icon-holder {
  color: $primary;
}

.side-nav .side-nav-inner .side-nav-menu li.dropdown.active > a {
  color: $default-dark;
}

.pointer {
  cursor: pointer;
}

ngb-tooltip-window, [_nghost-c1].tooltip {
  border: 0!important;
  padding: 0 0 5px 0!important;
}

.tooltip.show {
  width: 200px;
}

@media (min-width: 750px) {
  .modal-dialog {
    max-width: 650px;
  }
}

@include media-breakpoint-up(md) {
  .modal-xl .modal-dialog {
    max-width: 800px;
  }

  .sticky-45 {
    position: sticky;
    top: 45%;
    margin-bottom: 5 * $spacer;
  }
}
@media (min-width: 1050px) {
  .modal-lg {
    max-width: 1000px;
  }
}
@media (min-width: 1250px) {
  .modal-lg {
    max-width: 1200px;
  }
}
@media (min-width: 1650px) {
  .modal-lg {
    max-width: 1600px;
  }
}




//app-percentage-input > * {
//  max-width: 110px;
//}
//
//app-amount-input > * {
//  max-width: 140px;
//}

app-vat-input > div > * {
  flex: 3
}

app-vat-input > div > .input-group-append {
  flex: 2
}

.flex-3 {
  flex: 3;
}

.flex-2 {
  flex: 2;
}

.flex-1 {
  flex: 1;
}

.input-icon-append {
  position: relative;
}

.input-icon-append > * + span.append-close {
  right: 10px;
  left: unset;
  position: absolute;
  top: 12px;
  cursor: pointer;
  color: #999;
  font-size: 18px;
  font-weight: 400;
  width: 17px;
  display: inline-block;
  line-height: 1;
}

.input-icon-append .form-control {
  padding-right: 40px;
}

.wrap-text {
  white-space: normal;
}

app-booking-table {
  app-company-general-ledger-picker, app-company-vat-code-picker, app-company-integration-cost-center-picker, app-company-integration-cost-unit-picker {
    ng-select {
      min-width: 200px;
      max-width: 250px;
    }
  }

  app-percentage-input .input-group {
    width: 130px;
  }

  app-amount-input .input-group {
    width: 150px;
  }
}

th span.sortable > span {
  cursor: pointer;
  text-decoration: underline;
}

.line-height {
  line-height: 3em;
}

.modal-header {
  padding-bottom: 15px;
}

ng-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-left: 0;
  .ng-placeholder {
    padding-left: 0;
  }
}

.ocr-processing {
  background: $ocr-processing;
}

.form-highlight .ng-select-container {
  background: $ocr-processing;
}

.needs-ocr-review {
  background: $needs-ocr-review;
}

app-file-viewer > div > div {
  padding-bottom: 0!important;
}

.form-control {
  &::placeholder {
    color: #aaa;
  }

  &:disabled, &[readonly] {
    background-color: #f9f9f9;
  }
}

.transformer {
  .ng-dropdown-panel{
    width: auto !important;
  }
}

.boxlabel {
  .ng-dropdown-panel{
    width: auto !important;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 280px!important;
}

app-receipts-table table th {
  white-space: nowrap !important;
}

.no-wrap {
  white-space: nowrap;
}

.sticky-100 {
  position: sticky;
  top: 100px;
}

.height-250 {
  height: 250px;
}

.badge.badge-sale {
  background-color: $primary;
  color: $light;
}

.badge.badge-purchase {
  background-color: #ff8000;
  color: $light;
}

.badge {
  padding: 7px 10px 5px;
  background-color: darken($light, 5%);
  border-radius: 50px;
  text-transform: uppercase;
  color: #515365;
  font-weight: bold;

  &.badge-warning {
    background-color: $warning;
    color: $light;
  }

  &.badge-info {
    background-color: $info;
    color: $light;
  }

  &.badge-danger {
    background-color: $danger;
    color: $light;
  }

  &.badge-primary {
    background-color: $primary;
    color: $light;
  }

  &.badge-white {
    background-color: $white;
    color: $primary;
  }

  &.badge-secondary {
    background-color: $secondary;
    color: $light;
  }

  &.badge-outline-primary {
    background-color: $light;
    color: $primary;
  }

  &.badge-outline-secondary {
    background-color: $light;
    color: $secondary;
  }
}

.dropdown-item-description {
  margin: 1px 0 0px 10px;
  font-size: 10px;
}

app-valuta-exchange .form-group .form-group {
  margin-bottom: 0;
}

.circle-primary {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: $primary;
  margin-left: 4px;
  margin-right: 4px;
}

.manual-entry-overlay {
  position: relative;

  & > div {
    position: absolute;
    width: 100%;
    top: 0;
  }

  & > .overlay {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    z-index: 5;
    display: flex!important;

  }
}

/* Browser update styling */
body .buorg {
  position: absolute;
  position: fixed;
  z-index: 111111;
  width: 100%;
  top: 0px;
  left: 0px;
  border-bottom: 1px solid #A29330;
  text-align: center;
  color: #000;
  background-color: #fff8ea;
  font: $font-primary;
  font-size: small;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  animation: 1s ease-out 0s buorgfly
}
body #buorgul {
  background-color: $primary;
  color: $light;
  margin: 10px 2px;
}

body #buorgig {
  color: $default-dark;
  background-color: $default-white;
  border-color: darken($border-color, 5%);
  font-weight: 500;
  margin: 10px 2px;

  &:hover,
  &:focus,
  &:active {
    color: $default-dark;
    background-color: lighten($border-color, 2%);
    border-color: lighten($border-color, 2%);
  }
}

body.bu-active {
  .side-nav {
    top: 173px!important;
  }

  .side-nav-inner {
    overflow-y: scroll!important;
  }
}
/* End browser update styling */

.clearfix {
  clear: both;
}

@import 'klippa/loader';

.logo {
  width: 150px;
  height: 65px;
  background-position: left!important;
  background-image: image-set( $logo + '@1x.png' 1x, $logo + '@2x.png' 2x, $logo + '@3x.png' 3x);
}
